body{
  background-image: url('https://awjftp.blob.core.windows.net/awjcontents/websites/catch22/background.png');
  background-size: cover;
}

/*fonts*/
@font-face {
  font-family: 'bignoodletitling';
  src: url(fonts/bignoodletitling.ttf);
}

@font-face {
  font-family: 'gilroymedium';
  src: url(fonts/GilroyMedium.ttf);
}

.ft-bignoodletitling{
  font-family: 'bignoodletitling';
}

.ft-gilroymedium{
  font-family: 'gilroymedium';
}

.text-shadow {
  font-size: 4rem !important;
  font-weight: 700 !important;
  text-shadow: 3px 1px #f29100;
}

.mt-banner{
  margin-top: 100px;
}

.fs-banner{
  font-size: 8rem !important;
}

.text-form{
  color: #f29100;
  font-family: 'bignoodletitling';
  font-size: 3.5rem !important;
}

.form-input{
  background-color: transparent !important;
  color: #fff !important;
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: unset !important;
}

.btn-submit, .form-check-input:checked {
  background-color: #f29100 !important;
  border-color: #f29100 !important;
}

.btn-outline-danger {
  border: none !important;
  color: #fff !important;
  height: 20px !important;
  width: 20px !important;
  border-color: #fff !important;
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active {
  background-color: #f29100 !important;
  height: 20px !important;
  width: 20px !important;
}

.nd-options-label{
  position: relative;
  top: 20px;
}

.form-check-input{
  background-color: transparent !important;
}

@media only screen and (max-width: 500px) {
  .fs-banner{
    font-size: 5rem !important;
  }

  .text-form{
    font-size: 1.5rem !important;
  }
}